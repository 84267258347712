/** @jsx jsx */
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { Flex, jsx } from 'theme-ui'
import React from 'react'
import { useLocation } from '@reach/router'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import { PDPContext } from '../../Product/Page/PDPContext'
import RadioDisplayButtons from './RadioDisplayButtons'
import Text from '~/components/IntlComponents/Text'
import ProductAddToCartButton from '~/components/Product/AddToCartSection/Button'
import SubscribeButton from '~/components/Subscription/SubscribeButton'
import { getButtonHoverStyles } from '~/theme/overrides'

const SubscriptionChooser = ({
  comingSoon = false,
  onClick,
  perBoxPrice,
  sku,
  alternate,
  inFooter,
  isPasta,
}) => {
  const {
    subscription,
    resetSubscription,
    onChange,
    register,
    selectedInterval,
    setDefaultInterval,
    value,
    price,
    regularPrice,
    quantity,
    isDisabledByLocaleLimitations,
    apiError,
    isLoading,
    soldOut,
    outOfStock,
    priceAfterDiscount,
    product,
    promoDiscountPrice,
    discountPercentage: promoDiscountPercentage, // discount % for OMS/Contentful promo
    discountToSubscription, // if discount applies to Subs
  } = useContext(PDPContext)
  const { pathname } = useLocation()

  const { togglePerBoxDisplay } = subscription

  const sharedProps = {
    alternate,
    name: `subscription-type`,
    discountPercentage: subscription.discountPercentage, // subscription discount
    inFooter,
    onChange,
    perBoxPrice,
    presentation: subscription.presentation,
    price,
    register,
    resetSubscription,
    setDefaultInterval,
    value,
    promoDiscountPrice,
    promoDiscountPercentage,
    discountToSubscription,
  }

  const sharedStyles = t => ({
    flexShrink: 0,
    flexGrow: 1,
    width: '100%',
    minWidth: '200px',
    height: '50px',
    color: t.colors?.override?.altButtonColor ?? '#FFDD00',
    backgroundColor: t.colors?.override?.detailMain ?? 'override.detailMain',
    '&:hover': {
      ...getButtonHoverStyles(pathname),
    },
    '&:disabled': {
      cursor: 'not-allowed !important',
    },
    cursor: 'pointer !important',
    display: 'block',
    textTransform: 'capitalize',
  })

  const translate = useTranslate()

  return (
    <div
      sx={{
        display: alternate ? 'flex' : 'block',
        justifyContent: 'space-between',
        gap: '20px',
        width: '100%',
        marginTop: '30px',
      }}
    >
      <>
        <Text
          as="p"
          sx={{ color: 'secondary', m: 0, mb: '15px', p: 0 }}
          variant="text.eyebrow"
        >
          {translate('product.frequency')}
        </Text>
        <RadioDisplayButtons
          forwardSx={
            isPasta
              ? {
                  '& input:checked~svg': {
                    borderColor: ['secondary', 'secondary'],
                    color: 'primary',
                    backgroundColor: 'primary',
                  },
                  '& input~svg': {
                    borderColor: ['secondary', 'secondary'],
                    color: 'primary',
                  },
                }
              : null
          }
          localeSubscriptionToggle={togglePerBoxDisplay}
          {...sharedProps}
        />
      </>
      <Flex>
        {value !== 'subscribe-and-save' ? (
          <>
            <ProductAddToCartButton
              disabled={isDisabledByLocaleLimitations || soldOut}
              isDisabledByLocaleLimitations={isDisabledByLocaleLimitations}
              sx={t => sharedStyles(t)}
              isLoading={isLoading}
              apiError={apiError}
              soldOut={soldOut}
              outOfStock={outOfStock}
              comingSoon={comingSoon}
              price={price}
              quantity={quantity}
              regularPrice={regularPrice}
              promoDiscountPrice={promoDiscountPrice}
              onClick={onClick}
              sku={sku}
              isPasta={isPasta}
            />
          </>
        ) : (
          <>
            <SubscribeButton
              disabled={isDisabledByLocaleLimitations || soldOut}
              isDisabledByLocaleLimitations={isDisabledByLocaleLimitations}
              sx={t => sharedStyles(t)}
              isLoading={isLoading}
              onClick={onClick}
              soldOut={soldOut}
              outOfStock={outOfStock}
              comingSoon={comingSoon}
              price={price}
              apiError={apiError}
              useClickHandler
              quantity={quantity}
              regularPrice={regularPrice}
              sku={sku}
              subscription={subscription}
              interval={selectedInterval}
              isPasta={isPasta}
              discountToSubscription={discountToSubscription}
              promoDiscountPrice={
                discountToSubscription ? promoDiscountPrice : null
              }
            />
          </>
        )}
      </Flex>
    </div>
  )
}

SubscriptionChooser.propTypes = {
  subscription: PropTypes.shape({}),
  soldOut: PropTypes.bool,
  comingSoon: PropTypes.bool,
  price: PropTypes.number,
  regularPrice: PropTypes.number,
  sku: PropTypes.string.isRequired,
  textColor: PropTypes.string,
  inFooter: PropTypes.bool,
}

export default SubscriptionChooser
