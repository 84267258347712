/** @jsx jsx */
import { Fragment, useEffect, useState, useContext } from 'react'
import 'react-responsive-modal/styles.css'
import { Container, Flex, Grid, jsx, useColorMode, useThemeUI } from 'theme-ui'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import StickyAddToCart from './StickyAddToCart/index'
import RecirculationThreeUp from './RecirculationThreeUp'
import TextSectionWithVideo from './TextSectionWithVideo'
import { ProductDisplaySections } from './ProductDisplaySections'
import { PDPContext } from './PDPContext'
import Heading from '~/components/IntlComponents/Heading'
import localePrefix from '~/utils/intl/localePrefix'
import { SectionDivider } from '~/components/Nav/NavMenu/SectionDivider'
import ProductDetails from '~/components/Product/Details'

const ProductPage = () => {
  const {
    product,
    currentVariant,
    setCurrentVariant,
    value,
    setValue,
    isAvailable,
    isFetchingAvailability,
    handleProductSubscribe,
    handleProductAdd,
  } = useContext(PDPContext)
  const [, setColorMode] = useColorMode()

  useEffect(() => {
    setColorMode(product?.colorTheme?.slug ?? 'default-product')
  }, [product?.colorTheme?.slug, setColorMode])

  const {
    theme: { rawColors },
  } = useThemeUI()

  const translate = useTranslate()

  const [openWaitlist, setOpenWaitlist] = useState(false)

  const onOpenModalWaitList = () => setOpenWaitlist(true)
  const onCloseModalWaitList = () => setOpenWaitlist(false)

  const collectionRef = product

  const collection = collectionRef?.collection?.find(collection => collection)

  const [productIngredientRefObjectIndex, setIndex] = useState(0)

  const productIngredientRefObject = product

  const handleChange = cV => {
    setCurrentVariant(cV)
  }

  const handleSubmit = event => {
    value === 'subscribe-and-save'
      ? handleProductSubscribe(event)
      : handleProductAdd(event)
  }
  const soldOut = !isAvailable && !isFetchingAvailability

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <StickyAddToCart
          soldOut={soldOut}
          onOpenModal={onOpenModalWaitList}
          currentValue={value}
          handleVariantChange={handleChange}
          setCurrentValue={setValue}
          product={product}
          currentVariant={currentVariant}
        />
      </form>
      <form onSubmit={handleSubmit}>
        <Container
          p={0}
          sx={{
            height: ['unset', null, product.giftCard ? '80vh' : '100vh'],
            minHeight: '700px',
            maxHeight: [
              'unset',
              null,
              /dry-pasta/i.test(product.theme) ? '850px' : '900px',
            ],
          }}
        >
          <Flex
            sx={{
              flexDirection: ['column', 'column', 'row'],
              justifyContent: 'center',
            }}
          >
            <ProductDetails
              currentValue={value}
              onOpenModal={onOpenModalWaitList}
              onCloseModal={onCloseModalWaitList}
              open={openWaitlist}
              setCurrentValue={setValue}
              handleVariantChange={handleChange}
              product={product}
              soldOut={soldOut}
              collectionName={collection ? collection.slug : null}
            />
          </Flex>
        </Container>
      </form>
      {product.isGoods ? (
        <div sx={{ position: 'relative', width: '100%', paddingBottom: 200 }}>
          <Grid //needs to be grid or flex otherwise generates space for some reason
            sx={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              transform: 'scaleY(-1)',
              zIndex: 15,
            }}
          >
            <SectionDivider
              fill={
                rawColors.override?.sectionBackgroundAlt ??
                rawColors.override?.sectionBackground
              }
            />
          </Grid>
        </div>
      ) : (
        <ProductDisplaySections
          product={product}
          rawColors={rawColors}
          bubblesImage={null}
          //TODO: v--- this can be moved to Component scope
          productIngredientRefObjectIndex={productIngredientRefObjectIndex}
          setIndex={setIndex}
          productIngredientRefObject={productIngredientRefObject}
        />
      )}
      {/* 3-up product recirc */}
      <div
        sx={{
          paddingY: 50,
          paddingX: 50,
          backgroundColor:
            rawColors.override?.sectionBackgroundAlt ??
            rawColors.override?.sectionBackground,
          color: rawColors?.override?.sectionColorAlt ?? rawColors.primary,
        }}
      >
        <Heading
          variant="text.h3"
          sx={{ textAlign: 'center', marginBottom: 60 }}
        >
          {product?.productType?.slug === 'gifts' &&
          localePrefix().code === 'en-US'
            ? translate('product.related_products_gifts')
            : translate('product.related_products_general')}
        </Heading>
        {product.relatedProducts && (
          <RecirculationThreeUp
            products={product.relatedProducts}
            buttonStyle={{
              color: 'override.altButtonColor',
              backgroundColor: 'override.altButtonBackground',
              ':hover': {
                backgroundColor: 'accent',
              },
            }}
          />
        )}
      </div>
      {/* Tasting is beleiving */}
      <TextSectionWithVideo
        buttonUrl="/shop"
        url={product.maskedVideo?.file?.url}
        containerStyle={{
          backgroundColor:
            rawColors.override?.sectionBackgroundAlt ??
            rawColors.override?.sectionBackground,
          color: rawColors.override?.sectionColorAlt ?? rawColors.primary,
        }}
        buttonStyle={{
          color: 'override.altButtonColor',
          backgroundColor: 'override.altButtonBackground',
          ':hover': {
            backgroundColor: 'accent',
          },
        }}
      />
    </Fragment>
  )
}

export default ProductPage
