/** @jsx jsx */

import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { Flex, jsx, Select, Box } from 'theme-ui'
import React from 'react'

const ProductSelector = ({
  handleChange,
  options = [],
  defaultValue,
  optionsHaveGroups = false,
  ...props
}) => {
  const { register, getValues } = useForm()
  const onChange = e => {
    const newOption = e.target.options[e.target.selectedIndex]
    if (handleChange) handleChange(newOption?.value)
  }

  const renderOptions = () => {
    // Options that are grouped into categories will display in option

    if (optionsHaveGroups) {
      // if the product doesnt have the productType on contentful it wont show up on the selector
      // For special-items and new-launch items, we wont it to only show up if user has the direct link
      // so when user visits with direct links it will show the correct product
      // Usefull for special items and new launch items
      const defaultValueExists = options.some(({ options: op }) =>
        op.some(o => o.value === defaultValue),
      )
      const updatedOptions = [...options] // Make a copy of the options
      // If the defaultValue is not found in any existing type, add 'new launch' type
      if (!defaultValueExists) {
        updatedOptions.push({
          type: 'New',
          options: [
            {
              name: defaultValue.split('-').join(' ').toString(),
              value: defaultValue,
            },
          ],
        })
      }

      return updatedOptions.map(({ options: op, type }) => (
        <>
          {options.length > 1 && (
            <option value={''} key={type} disabled={true}>
              {type}
            </option>
          )}
          {op
            ?.filter(
              (v, i, arr) =>
                v &&
                v.value &&
                v.value.indexOf('-single') === -1 &&
                arr.findIndex(vv => vv && vv.value === v.value) === i,
            )
            ?.map(({ name, value, path }) => (
              <option key={value} value={value} data-path={path}>
                {name || value}
              </option>
            ))}
        </>
      ))
    }
    // Options that are not categorized will display without groups
    return options
      ?.filter(
        (v, i, arr) =>
          v &&
          v.value &&
          v.value.indexOf('-single') === -1 &&
          arr.findIndex(vv => vv && vv.value === v.value) === i,
      )
      ?.map(({ name, value, path }) => (
        <option key={value} value={value} data-path={path}>
          {name || value}
        </option>
      ))
  }

  return (
    <Flex
      sx={{
        width: '100%',
        '& > div': {
          width: 'inherit',
        },
      }}
    >
      <Select
        sx={{
          textTransform: 'capitalize',
        }}
        arrow={
          <Box
            as="svg"
            width="11"
            height="6"
            viewBox="0 0 11 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            sx={{
              ml: -28,
              alignSelf: 'center',
              pointerEvents: 'none',
              color: 'override.detailMain',
            }}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.01213 6L10.002 0.863219L9.14367 0L4.99197 4.27397L0.840277 0L0.00195312 0.863219L4.99177 6H5.01213Z"
              fill="currentColor"
            />
          </Box>
        }
        {...register('product')}
        defaultValue={defaultValue}
        onChange={onChange}
        {...props}
      >
        {renderOptions()}
      </Select>
    </Flex>
  )
}

ProductSelector.propTypes = {
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }).isRequired,
  ),
}

export default ProductSelector
