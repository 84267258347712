import React from 'react'
import { Helmet } from 'react-helmet'

const BVProductSchemaScript = ({ product, isBundle = false }) => {
  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'Product',
          '@id': `https://www.goodles.com/${
            isBundle ? 'bundles' : 'products'
          }/${product.slug}/`,
          name: product.name,
          brand: 'Goodles',
        })}
      </script>
    </Helmet>
  )
}

export default BVProductSchemaScript
