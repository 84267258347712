import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { useAnalytics } from '@chordcommerce/gatsby-theme-autonomy'
import { PDPProvider } from '../components/Product/Page/PDPContext'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import ProductPage from '~/components/Product/Page'
import BVProductSchemaScript from '~/components/Reviews/Script/BVProductSchemaScript'

const ProductTemplate = ({ data }) => {
  const { product } = data
  const { trackProductViewed } = useAnalytics()
  useEffect(() => {
    if (typeof window !== 'undefined') {
      trackProductViewed({ product })
    }
  })
  const disabledForLocale =
    product?.disabledForLocale ||
    (product?.disabledForLocale === null && product.locale !== 'en-US') ||
    !product
  if (disabledForLocale) {
    if (typeof window !== 'undefined' && product && product.locale) {
      window.location = `/${product.locale}/`
    }
    return null
  }
  const { isGoods } = product

  const metaImage = isGoods
    ? product?.stickAddToCartImage?.gatsbyImageData.src
    : product?.mainImage?.gatsbyImageData.src

  const logoColors = product.logoColors

  return (
    <Layout logoColors={logoColors} theme={product?.colorTheme?.slug}>
      <PDPProvider product={product}>
        <Metadata
          title={product.name}
          description={product.shortDescription ? product.shortDescription : ''}
          image={metaImage}
        />
        <BVProductSchemaScript product={product} />
        <ProductPage />
      </PDPProvider>
    </Layout>
  )
}

export const query = graphql`
  query ProductQuery($slug: String, $locale: String) {
    product: contentfulProduct(
      slug: { eq: $slug }
      node_locale: { eq: $locale }
    ) {
      __typename
      ...ProductPageFragment
      logoColors
      theme
      disabledForLocale
      locale: node_locale
    }
  }
`

export default ProductTemplate
