import React, { createContext, useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import useLocaleCartLogic from '~/utils/intl/context/hooks/use-locale-cart-logic'
import { useTikTokHelper } from '~/components/TikTokHelper'
import { ChordCartContext } from '~/components/Mini-Cart/CartContext'
import useVariantAvailability from '~/hooks/components/use-variant-availability'
import { useAddToCart } from '~/hooks/utils/useAddToCart'
import { pinterestAddToCart } from '~/components/Pinteresthelper'

export const PDPContext = createContext({
  apiError: null,
  currentVariant: {},
  handleProductAdd: () => {},
  handleProductSubscribe: () => {},
  isDisabledByLocaleLimitations: false,
  isFetchingAvailability: false,
  isLoading: false,
  kit: {},
  name: '',
  onChange: () => {},
  price: '',
  priceAfterDiscount: null,
  product: {},
  productAdded: false,
  quantity: 1,
  register: () => {},
  regularPrice: 0,
  resetSubscription: () => {},
  selectedInterval: null,
  setAddToCartDisabled: false,
  setCurrentVariant: () => {},
  setDefaultInterval: () => {},
  setProductAdded: () => {},
  setQuantity: () => {},
  setValue: () => {},
  soldOut: false,
  subscription: {},
  value: '',
  variantHasSubscriptionInCart: false,
  variantInCart: false,
  watch: () => {},
})

export const PDPProvider = ({ children, product, kit }) => {
  //#region Hooks and Methods

  const { hasGroupHitQuantityLimit } = useLocaleCartLogic()
  const { addSubscription, addToCart } = useAddToCart()

  const isDisabledByLocaleLimitations = hasGroupHitQuantityLimit(product?.slug)
  const { viewContent } = useTikTokHelper()
  const [productAdded, setProductAdded] = useState(false)
  const [selectedQuantity, setSelectedQuantity] = useState(1)
  const { lineItems } = useContext(ChordCartContext)
  const [isLoading, setIsLoading] = useState(false)
  const [apiError, setApiError] = useState(null)

  const translate = useTranslate()

  const [currentVariant, setCurrentVariant] = useState(
    product && product.variants && product.variants.length > 0
      ? product.variants[0]
      : null,
  )

  const { register, unregister, watch } = useForm()
  const [addToCartDisabled, setAddToCartDisabled] = useState(false)

  const subscription = product?.subscription || {}
  const { price, regularPrice } = currentVariant || {}
  const priceAfterDiscount = price * (1 - subscription.discountPercentage / 100)

  const name = watch('subscription-type')

  const discountPercentage = currentVariant?.discountPercentage
  const discountToSubscription = currentVariant?.discountToSubscription

  // To show chord promo pricing on PDP based on the % set in contentful
  // % should MATCH the promo rules on OMS
  const promoDiscountPrice = discountPercentage
    ? price - (discountPercentage / 100) * price
    : null

  // check if any variant has a OMS Promo related discounts.
  const variantHasPromoDiscount = product?.variants?.find(
    v => !!v.discountPercentage,
  )

  const [value, setValue] = useState(
    product.subscription && !variantHasPromoDiscount
      ? 'subscribe-and-save'
      : 'one-time-purchase',
  )
  const [selectedInterval, setSelectedInterval] = useState(null)

  const setDefaultInterval = () => {
    setSelectedInterval(
      product?.subscription?.intervals
        ? product?.subscription.intervals[0]
        : null,
    )
  }

  const variantHasSubscriptionInCart = lineItems?.some(
    lineItem =>
      (lineItem.variant.sku === currentVariant.sku ||
        lineItem.variant.sku ===
          currentVariant?.usePreFilledBundle?.masterSku) &&
      lineItem.subscriptionLineItems.length > 0,
  )
  const variantInCart = lineItems?.some(
    lineItem =>
      lineItem.variant.sku === currentVariant.sku ||
      lineItem.variant.sku === currentVariant.usePreFilledBundle?.masterSku,
  )

  const { isAvailable, isFetchingAvailability } = useVariantAvailability({
    sku: currentVariant.sku,
  })
  const { comingSoon, hidden } = product

  const outOfStock = !isAvailable || hidden
  const soldOut = currentVariant.soldOut

  useEffect(() => {
    // set subscription interval on load
    setDefaultInterval()
  }, [setDefaultInterval])

  const onChange = e => {
    setValue(e.target.value)
  }

  const resetSubscription = () => {
    unregister('subscription-interval')
    setSelectedInterval(null)
  }
  // Check if there is a pre filled bundle for this variant (e.g. 6-Pack Cheddy [SKU:1700] ~~ Cheddy Mac 6-Pack bundle)
  // If Pre filled bundle is present it will be prefered over the variant
  // Used this method so the user experience remains unchanged but the OMS/warehouse can use the single SKUs instead of discountinued 6-pack
  // can be used for any variant as long as they have a bundle
  //
  // Notes:
  // - Original variant (e.g. - 6-Pack Cheddy [SKU:1700]) should be published and in stock in OMS as we want it to show up on PDP
  // - OMS bundle (pre filled bundles) are different from the CUSTOM BUILD YOUR OWN BUNDLE currently in the works.

  const preFilledBundle = currentVariant?.usePreFilledBundle

  const handleProductAdd = async event => {
    if (event) event.preventDefault()
    if (addToCartDisabled || soldOut || outOfStock) {
      return
    }

    setIsLoading(true)
    setApiError(null)

    try {
      if (variantInCart && variantHasSubscriptionInCart) {
        setApiError(translate('error.subscription.default'))
        return
      } else {
        const finalSku = preFilledBundle
          ? preFilledBundle.masterSku
          : currentVariant.sku
        await addToCart(finalSku, selectedQuantity)
        pinterestAddToCart(finalSku, selectedQuantity)
      }
    } catch (error) {
      setApiError(translate('error.api.default'))
    } finally {
      setIsLoading(false)
    }
  }

  const handleProductSubscribe = async event => {
    if (event) event.preventDefault()
    if (addToCartDisabled || comingSoon || soldOut || outOfStock) {
      return
    }

    setIsLoading(true)
    setApiError(null)

    try {
      if (variantInCart && !variantHasSubscriptionInCart) {
        setApiError(translate('error.subscription.default'))
        return
      }
      const finalSku = preFilledBundle
        ? preFilledBundle.masterSku
        : currentVariant.sku

      await addSubscription({
        sku: finalSku,
        quantity: selectedQuantity,
        interval: selectedInterval,
      })
      pinterestAddToCart(finalSku, selectedQuantity)
      setProductAdded(true)
    } catch (error) {
      console.error(error)
      setApiError(translate('error.api.default'))
    } finally {
      setIsLoading(false)
      dispatchEvent(new CustomEvent('dk_cart_updated'))
    }
  }

  useEffect(() => {
    viewContent({
      contentType: 'product',
      contentId: product.slug,
      description: product.shortDescription,
    })
  }, [])

  //#endregion
  const ctx = {
    apiError,
    currentVariant,
    handleProductAdd,
    handleProductSubscribe,
    isDisabledByLocaleLimitations,
    isFetchingAvailability,
    isLoading,
    kit,
    name,
    onChange,
    price,
    priceAfterDiscount,
    promoDiscountPrice,
    discountPercentage,
    discountToSubscription,
    variantHasPromoDiscount,
    product,
    productAdded,
    quantity: selectedQuantity,
    register,
    regularPrice,
    resetSubscription,
    selectedInterval,
    setAddToCartDisabled,
    setCurrentVariant,
    setDefaultInterval,
    setProductAdded,
    setQuantity: setSelectedQuantity,
    setValue,
    outOfStock,
    soldOut,
    subscription,
    value,
    variantHasSubscriptionInCart,
    variantInCart,
    watch,
  }
  return <PDPContext.Provider value={ctx}>{children}</PDPContext.Provider>
}
