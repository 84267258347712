/** @jsx jsx */
import React, { useEffect, useState } from 'react'
import { Flex, jsx, useThemeUI } from 'theme-ui'
import { Global } from '@emotion/react'
import Heading from '~/components/IntlComponents/Heading'
import { useStarFilter } from '~/hooks/utils/useStarFilter'

const BazaarVoiceWidget = ({ product }) => {
  const { theme, colorMode } = useThemeUI()
  const starFilter = useStarFilter(product?.yotpoId, false, colorMode, '')

  return (
    <Flex sx={{ alignItems: 'center', flexDirection: 'column' }}>
      <span
        sx={{
          color: 'secondary',
          textAlign: 'center',
          mb: '5rem',
          width: '100%',
        }}
      >
        <Heading variant="text.h3">{product.reviewHeading}</Heading>
      </span>
      <Global
        // Styles for Write the preview Pop-up as it is at root level
        styles={{
          '& [data-bv-show="inpage_submission"]': {
            '& [role="dialog"]': {
              backgroundColor: theme.colors.primary,
              maxHeight: '85vh',
              padding: '2rem',
              color: `${theme.colors.secondary} !important`,
              'p, label, button, textarea,input, span, h2, h3, div': {
                color: `${theme.colors.secondary} !important`,
              },
              clipPath: 'inset(0 round 16px)',
              '& p:focus, & div:focus': {
                outlineColor: `${theme.colors.secondary} !important`,
              },
              borderRadius: '1rem',
              '& div, & p, & fieldset, & textarea, & input, & span': {
                borderColor: `${theme.colors.secondary} !important`,
              },
              '& textarea, & input': {
                boxShadow: 'none !important',
                ':focus': {
                  outlineColor: `${theme.colors.secondary} !important`,
                },
              },
              '& svg': {
                filter: starFilter,
              },
              '[aria-label="Add images"], [aria-label="Your reviews"], [aria-label="Personal/Product Information"], [aria-label="Product Rating"]':
                {
                  '& span': {
                    padding: '0.5rem',
                    backgroundColor: `${theme.colors.secondary} !important`,
                    color: `${theme.colors.primary} !important`,
                  },
                  '& > :first-child': {
                    backgroundColor: `${theme.colors.secondary} !important`,
                    color: `${theme.colors.primary} !important`,
                    aspectRatio: '1',
                    padding: '0.5rem',
                    borderColor: `${theme.colors.secondary} !important`,
                  },
                },
              '[aria-label="Submit Your reviews."], [aria-label="Submit Add images."], [aria-label="Skip Add images."], [aria-label="Submit Product Rating."], [aria-label="Skip Product Rating."], [aria-label="Submit Personal/Product Information."], #bv-ips-undefined':
                {
                  backgroundColor: `${theme.colors.secondary} !important`,
                  borderColor: `${theme.colors.primary} !important`,
                  '& span ': {
                    color: `${theme.colors.primary} !important`,
                  },
                },
              // yes/no button in write a review "Would you recommend this product to a friend?"" hover
              '[aria-labelledby="agreement-termsAndConditions"], div[id="3_isrecommended-false"][aria-checked="true"], div[id="3_isrecommended-true"][aria-checked="true"]':
                {
                  backgroundColor: `${theme.colors.secondary} !important`,
                  color: `${theme.colors.primary} !important`,
                  '& svg': {
                    stroke: `${theme.colors.primary} !important`,
                  },
                },
              '[aria-label="Your reviews"]': {
                '& svg': {
                  '& g': {
                    stroke: `${theme.colors.primary} !important`,
                  },
                },
              },
            },
          },
        }}
      />
      <Flex
        id="review-widget"
        sx={{
          marginBottom: 50,
          paddingInline: '25px',
          maxWidth: '1440px',
          '& #bv_review_maincontainer > div:first-child': {
            h2: {
              display: 'none !important',
            },
          },
          '& abbr > svg, & li > svg': {
            filter: `${starFilter} !important`,
          },
          '#bv-popup-47': {
            backgroundColor: 'primary',
          },
          '.bv_rating_content3': {
            svg: {
              g: {
                fill: `${theme.colors.secondary} !important`,
              },
            },
          },
          // Search Input
          input: {
            backgroundColor: 'primary',
            color: `${theme.colors.secondary} !important`,
            border: `1px solid ${theme.colors.secondary} !important`,
            outline: 'none !important',
            '::placeholder': {
              color: `${theme.colors.secondary} !important`,
            },
          },
          '.bv-rnr__ppunu1-0': {
            border: `2px solid ${theme.colors.secondary} !important`,
          },
          '.bv-rnr__ppunu1-1': {
            color: `${theme.colors.secondary} !important`,
            backgroundColor: `${theme.colors.secondary} !important`,
          },
          '#reviews_container': {
            '& button': {
              path: {
                stroke: `${theme.colors.secondary} !important`,
              },
            },
            '& li': {
              'g, path': {
                stroke: 'unset !important',
              },
            },
          },

          'ul[role="presentation"]': {
            'button , a': {
              border: `${theme.colors.secondary} !important`,
            },
            g: {
              stroke: 'transparent !important',
            },
          },
          '[role="search"]': {
            '& svg': {
              fill: 'secondary',
            },
          },
          '& button': {
            svg: {
              fill: 'secondary',
            },
          },
          '& g': {
            '& :last-child': {
              stroke: `${theme.colors.secondary} !important`,
            },
          },
          path: {
            color: `${theme.colors.secondary} !important`,
          },
          ul: {
            backgroundColor: 'primary',
            '& span:hover': {
              backgroundColor: `${theme.colors.secondary} !important`,
              '& div': {
                color: `${theme.colors.primary} !important`,
              },
            },
          },
          //svg inside the dropdown
          '[id*="bv-questions-filter"]': {
            'li:hover': {
              svg: {
                stroke: `${theme.colors.primary} !important`,
                fill: `${theme.colors.primary} !important`,
              },
            },
            svg: {
              stroke: `${theme.colors.secondary} !important`,
              fill: `${theme.colors.secondary} !important`,
            },
          },
          // SVG inside Rating dropdown
          '#bv-reviews-filter-Rating': {
            'li:hover': {
              'svg > path': {
                fill: `${theme.colors.primary} !important`,
              },
            },
            'svg > path': {
              stroke: 'transparent !important',
              fill: `${theme.colors.secondary} !important`,
            },
          },
          // remove active filter "x"
          '[aria-label*="Remove active filter Rating"]': {
            'svg > path': {
              fill: `${theme.colors.secondary} !important`,
            },
          },
          'ul [role="presentation"]': {
            '& span:hover': {
              backgroundColor: `transparent !important`,
            },
          },
          button: {
            borderColor: 'secondary',
          },
          div: {
            borderColor: 'secondary',
          },
          '#bv-reviews-overall-ratings-container': {
            div: {
              justifyContent: 'center',
            },
          },
          section: {
            '& g[fill-rule="nonzero"]': {
              fill: `${theme.colors.secondary} !important`,
            },
          },
          '[data-bv-v="contentItem"]': {
            '& div': {
              borderColor: 'transparent !important',
            },
          },
          '& #bv_review_maincontainer, h2,h3, .bv_rating_content1, .bv_rating_content2, .bv_rating_content3, button, [data-bv-v="contentSummary"], span, div':
            {
              color: `${theme.colors.secondary} !important`,
            },
          '[role="group"] > [role="button"] > div': {
            backgroundColor: `transparent !important`,
            borderWidth: '3px !important',
            borderColor: `${theme.colors.secondary} !important`,
          },
        }}
      >
        <div data-bv-show="reviews" data-bv-product-id={product?.yotpoId} />
      </Flex>
    </Flex>
  )
}

export default BazaarVoiceWidget
