export const pinterestAddToCart = (
  finalSku,
  selectedQuantity,
  selectedInterval,
) => {
  if (typeof window !== 'undefined') {
    window.pintrk('track', 'addtocart', {
      event_id: 'eventId0002',
      currency: 'USD',
      line_items: [
        {
          product_id: finalSku,
          product_quantity: selectedQuantity,
        },
      ],
    })
  }
}
